import Image from "next/image";
import Link from "next/link";
import Customers from "../../AiDataLake/AiCustomers/Customers";
import AiLanding from "../../AiDataLake/AiLandinngPage/AiLanding";
import Button from "../../Common/Button/Button";
import styles from "./Component3.module.scss";

const Component3 = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <div className={"tag"}>
          <span> What’s new?</span>
        </div>
        <p className={styles["heading"]}>Our Headless commerce solution</p>
        <p className={styles["supporting-text"]}>for all you business needs</p>
        <Link href="product-stack">
          <Button text="Check it out" classname="button-wrapper" />
        </Link>
      </div>
      <div className={styles["images-div"]}>
        <Image
          width={244}
          height={498}
          src="/screen-mockup3.webp"
          alt="screen-mockup"
          className={styles["iphone-mockup"]}
        />
        <Image
          width={816}
          height={616}
          src="/screen-mockup2.webp"
          alt="screen-mockup"
          className={styles["tablet-screen"]}
        />
      </div>
      <div className={styles["ai-landing"]}>
        <AiLanding />
        <Link href="/eTail-data-lake">
          <Button text="Know More" />
        </Link>
        <Customers />
      </div>
    </div>
  );
};

export default Component3;
