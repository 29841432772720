import React from "react";
import styles from "./Stats.module.scss";

interface StatsProps {
    data: any;
    statsStyle?: React.CSSProperties;
    spanStyle?: React.CSSProperties;
    column?: 4 | 6; // Allow only 4 or 6 columns
}

const Stats: React.FC<StatsProps> = ({
    data,
    statsStyle = {},
    spanStyle = {},
    column = 4, 
}) => {
    const columnClass = column === 6 ? "columns-6" : "columns-4";

    return (
        <div className={`${styles["stats-container"]} ${styles[columnClass]}`}>
            <div className={styles["stats-head"]}>
                <h1>{data.title}</h1>
                <p>{data.subTitle}</p>
            </div>

            <div className={`${styles["stats"]} ${styles[columnClass]}`} style={statsStyle}>
                {data.numbers.map((item: any, index: number) => (
                    <div className={styles["stats-card"]} key={`stats-${item.title}-${index}`}>
                        <span style={spanStyle}>{item.count}</span>
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Stats;
